const webCommonService = {
  fileName: "remoteEntry.js",
  name: "OP_WEB_COMMON_SERVICE",
  port: 3001,
  get url() {
    return window.location.protocol === "http:"
      ? `http://${window.location.hostname}:3001/webcommon`
      : `${window.location.origin}/webcommon`;
  },
  urlGlobalVariable: "OP_WEB_COMMON_SERVICEUrl",
  get federationConfig() {
    return `${this.name}@[window.${this.urlGlobalVariable}]/${this.fileName}`;
  }
};

const webLoanService = {
  fileName: "remoteEntry.js",
  name: "OP_WEB_LOAN_SERVICE",
  port: 3001,
  get url() {
    return window.location.protocol === "http:"
      ? `http://${window.location.hostname}:3002/webloan`
      : `${window.location.origin}/webloan`;
  },
  urlGlobalVariable: "OP_WEB_LOAN_SERVICEUrl",
  get federationConfig() {
    return `${this.name}@[window.${this.urlGlobalVariable}]/${this.fileName}`;
  }
};

const appraisalDashboardModule = {
  fileName: "remoteEntry.js",
  name: "appraisal_dashboard_module",
  port: 3004,
  get url() {
    return window.location.protocol === "http:"
      ? `https://dev.merchantsmtg.info/appraisal_dashboard`
      : `${window.location.origin}/appraisal_dashboard`;
  },
  urlGlobalVariable: "appraisal_dashboard_moduleUrl",
  get federationConfig() {
    return `${this.name}@[window.${this.urlGlobalVariable}]/${this.fileName}`;
  }
};

module.exports = {
  webCommonService,
  webLoanService,
  appraisalDashboardModule
};
