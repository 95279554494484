/* eslint-disable import/extensions */
import {
  appraisalDashboardModule,
  webCommonService
} from "./mfe-remotes-config/remoteConfig.js";

window[webCommonService.urlGlobalVariable] = webCommonService.url;
window[appraisalDashboardModule.urlGlobalVariable] =
  appraisalDashboardModule.url;

import("./bootstrap.jsx");
